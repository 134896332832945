import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Login from '../login/Login'
import Navbar from '../navbar/Navbar'
import Proposals from '../proposals'
import CompetitionInfo from '../competition-info'
import Profile from '../profile/Profile'
import Settings from '../settings'
import SingleProposal from '../single-proposal'
import Comparing from '../comparing'
import PublicComponent from './PublicComponent'
import PrivateComponent from './PrivateComponent'
import { styled } from '@material-ui/styles'

import Footer from '../footer/Footer'
import { Helmet } from 'react-helmet'

const AppBackGroundColor = styled('div')(({ theme }) => ({
  backgroundColor: theme.modeColors.rootBackGround,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
}))

const readOnly = process.env.REACT_APP_READ_ONLY

export default function App({ themeMode, toggleState }) {
  if (readOnly) {
    return (
      <AppBackGroundColor>
        <Navbar />
        <Switch>
          <Route exact path="/" component={Proposals} />
          <Route exact path="/competition-info" component={CompetitionInfo} />
          <Route exact path="/proposal/:id" component={SingleProposal} />
          <Redirect from="*" to="/" />
        </Switch>
      </AppBackGroundColor>
    )
  }

  return (
    <AppBackGroundColor>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-23D6SRSQDH"
        ></script>
        <script type="text/javascript">
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'G-23D6SRSQDH');
        `}
        </script>
      </Helmet>
      <Navbar />
      <Switch>
        <Route
          exact
          path="/login"
          render={() => <PublicComponent component={Login} />}
        />
        <Route
          exact
          path="/"
          render={() => <PrivateComponent component={Proposals} />}
        />
        <Route
          exact
          path="/competition-info"
          render={() => <PrivateComponent component={CompetitionInfo} />}
        />
        <Route
          exact
          path="/profile"
          render={() => <PrivateComponent component={Profile} />}
        />
        <Route
          exact
          path="/settings"
          render={() => (
            <PrivateComponent
              component={() => (
                <Settings themeMode={themeMode} clicked={toggleState} />
              )}
            />
          )}
        />
        <Route
          exact
          path="/proposal/:id"
          render={() => <PrivateComponent component={SingleProposal} />}
        />
        <Route
          exact
          path="/comparing"
          render={() => <PrivateComponent component={Comparing} />}
        />
      </Switch>
      <Footer />
    </AppBackGroundColor>
  )
}

// class App extends Component {
//   state = {
//     activeSettingsTab: 0
//   }

//   setActiveSettingsTab = activeSettingsTab => {
//     this.setState({ activeSettingsTab })
//   }

//   render() {
//     const readOnly = process.env.REACT_APP_READ_ONLY
//     const { themeMode, toggleState } = this.props

//     if (readOnly) {
//       return (
//         <AppBackGroundColor>
//           <Navbar />
//           <Switch>
//             <Route exact path="/" component={Proposals} />
//             <Route exact path="/competition-info" component={CompetitionInfo} />
//             <Route exact path="/proposal/:id" component={SingleProposal} />
//             <Redirect from="*" to="/" />
//           </Switch>
//         </AppBackGroundColor>
//       )
//     }
//     return (
//       <AppBackGroundColor>
//         <Helmet>
//           <script
//             async
//             src="https://www.googletagmanager.com/gtag/js?id=G-GYQ6DF1HWX"
//           ></script>
//           <script type="text/javascript">
//             {`
//               window.dataLayer = window.dataLayer || [];
//               function gtag(){dataLayer.push(arguments);}
//               gtag('js', new Date());

//               gtag('config', 'G-GYQ6DF1HWX');
//         `}
//           </script>
//         </Helmet>
//         <Navbar />
//         <Switch>
//           <Route
//             exact
//             path="/login"
//             render={() => <PublicComponent component={Login} />}
//           />
//           <Route
//             exact
//             path="/"
//             render={() => <PrivateComponent component={Proposals} />}
//           />
//           <Route
//             exact
//             path="/competition-info"
//             render={() => <PrivateComponent component={CompetitionInfo} />}
//           />
//           <Route
//             exact
//             path="/profile"
//             render={() => <PrivateComponent component={Profile} />}
//           />
//           <Route
//             exact
//             path="/settings"
//             render={() => (
//               <PrivateComponent
//                 component={() => (
//                   <Settings
//                     themeMode={themeMode}
//                     clicked={toggleState}
//                     activeSettingsTab={this.state.activeSettingsTab}
//                     setActiveSettingsTab={this.setActiveSettingsTab}
//                   />
//                 )}
//               />
//             )}
//           />
//           <Route
//             exact
//             path="/proposal/:id"
//             render={() => <PrivateComponent component={SingleProposal} />}
//           />
//           <Route
//             exact
//             path="/comparing"
//             render={() => <PrivateComponent component={Comparing} />}
//           />
//         </Switch>
//         <button
//           style={{
//             position: 'fixed',
//             right: -28,
//             bottom: 100,
//             backgroundColor: '#999da0',
//             color: '#f7f8f9',
//             zIndex: 2000,
//             transform: 'rotate(-90deg)',
//             border: 0,
//             padding: '3px 10px 2px',
//             fontSize: 9,
//             cursor: 'pointer'
//           }}
//           onClick={() => {
//             window.Usersnap.show(
//               `${process.env.REACT_APP_USERSNAP_API_KEY}`
//             ).then(widgetApi => widgetApi.open())
//           }}
//         >
//           Feedback ?
//         </button>
//         <Footer />
//       </AppBackGroundColor>
//     )
//   }
// }

// export default App
